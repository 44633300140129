.loader:empty {
  position: absolute;
  top: calc(50% - 2em);
  left: calc(50% - 2em);
  width: 4em;
  height: 4em;
  border-radius: 50%;
  border: 3px solid #01133e;
  border-color: #01133e transparent #01133e transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingContainer {
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
}

.loading {
  position: absolute;
  top: calc(50% - 2em);
  left: calc(50% - 2em);
  width: 4em;
  height: 4em;
  border-radius: 50%;
  border: 3px solid #01133e;
  border-color: #01133e transparent #01133e transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
